import React, { useState } from 'react';
import { Link } from 'gatsby';
import Menu from './menu/index';

const Header = ({ title }) => {
    const [ menu, setMenu ] = useState(false);

    return <header className="w-full bg-primary flex flex-col lg:bg-secondary lg:max-w-sm lg:min-h-screen lg:pt-6">
        <div className="flex p-4 lg:p-6 lg:pb-0">
            <div className="flex-1">
                <h1 className="text-white text-base lg:text-secondary lg:font-semibold lg:border-b-2 lg:pb-6 lg:mb-6">
                    <Link to="/" className="text-white lg:text-secondary hover:text-white focus:text-white hover:no-underline focus:no-underline lg:focus:text-secondary lg:hover:text-secondary">
                        <svg className="h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 241.6 71">
                            <g id="Layer_2" data-name="Layer 2">
                                <path className="fill-current text-white lg:text-blue-900" d="M95.3 13h22.6v7.7h-14.4v10.8h13.8v7.7h-13.8v18.2h-8.2zM123.7 13h8v44.4h-8zM138 25.3h8v32.1h-8zM186.6 24.1v27.2c0 16-11.9 19.7-18.2 19.7s-13.8-3.6-16.7-11.1h9.1a10.2 10.2 0 008.2 3.7c4.5 0 9.8-3.1 10.2-10.3v-.2a12.6 12.6 0 01-10.9 5.4c-8.1 0-17-6.9-17-18.1A17.2 17.2 0 01168.1 23c6.9 0 9.8 3.9 10.7 5.2h.1v-4.1zm-17.7 6.2a10 10 0 00-9.8 10.2c0 6.8 5.1 10.7 10 10.7s9.5-4.1 9.5-10.8c0-4.7-3.8-10.1-9.7-10.1zM192.1 13h8v13.4a11.9 11.9 0 018.6-3.4 13.8 13.8 0 0110.3 5.1c2.6 3.3 2.6 8.1 2.6 10.5v18.8h-8v-17c0-3.6-.2-10.1-6.8-10.1s-6.7 7.4-6.7 10.2v16.9h-8zM228.8 31.2h-3.9v-7.1h3.9V13h8v11.1h4.8v7.1h-4.8v26.2h-8z" />
                                <circle className="fill-current text-white lg:text-blue-900" cx="142.1" cy="17.1" r="4.5" />
                                <path className="fill-current text-white lg:text-primary" d="M34.4 0a34.4 34.4 0 1034.4 34.4A34.4 34.4 0 0034.4 0zm25.8 27.8a7.2 7.2 0 00-6-.5C44.4 31 41.4 43.9 29.5 45.6 19.7 47 10.9 38.8 8.6 28.7c4.1 2.8 9 7.2 15.4 8.4 9.5 1.5 12.3-10.6 25.2-13.7 3.4-.9 9.7-.1 11 4.5z" />
                            </g>
                        </svg>
                        <span className="block w-0 h-0 overflow-hidden">{title}</span>
                    </Link>
                </h1>
            </div>

            <div className="flex text-right content-center justify-end lg:hidden">
                <button onClick={() => setMenu(!menu)}>
                    {(!menu) ? <svg className="w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 12">
                        <g fill="#FFF" fillRule="evenodd" opacity=".8">
                            <rect width="16" height="2" rx=".5" />
                            <rect y="5" width="16" height="2" rx=".5" />
                            <rect y="10" width="16" height="2" rx=".5" />
                        </g>
                    </svg> : <svg className="w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14">
                        <g transform="translate(-1 -1)" fill="#FFF" fillRule="evenodd" opacity=".8">
                            <rect transform="rotate(45 8 8)" x="-1" y="7" width="18" height="2" rx=".5" />
                            <rect transform="scale(-1 1) rotate(45 0 -11.314)" x="-1" y="7" width="18" height="2" rx=".5" />
                        </g>
                    </svg>}
                </button>
            </div>
        </div>

        <Menu isVisible={menu} />
    </header>
};

export default Header;
