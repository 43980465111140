import React from 'react';
import { Link } from 'gatsby';
import { Location } from '@reach/router';

const MenuLink = ({ id, item }) => {
    return <Location>
        {({ location }) => {
            const isActive = location.pathname === item.path || location.pathname === `${item.path}/`;

            return <li key={id}><Link to={item.path} className={`${isActive ? `lg:bg-secondary-active` : ``} text-sm relative block pl-8 py-2 text-white hover:bg-primary-active lg:text-secondary lg:pl-4 lg:mx-6 lg:py-3 lg:hover:bg-secondary-active lg:rounded-sm hover:no-underline hover:text-white lg:hover:text-secondary focus:no-underline focus:text-white lg:focus:text-secondary lg:focus:bg-secondary-active lg:font-normal`}>
                <span className={`${isActive ? `inline-block` : `hidden`} align-middle h-6 bg-white w-3px rounded-l-sm absolute top-0 bottom-0 m-auto -ml-4 lg:bg-primary lg:h-auto lg:w-1`}></span>
                {item.title}
            </Link></li>
        }}
    </Location>
};

export default MenuLink;
