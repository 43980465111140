import '../styles/tailwind.css';
import React, { useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import Header from './header';

const Layout = ({ children }) => {
    const [ loaded, setLoaded ] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setLoaded(true);
        }, 250);
    }, []);

    return (
        <>
            <Helmet title="Flight" defer={false} />
            <Helmet>
                <html lang="en" />
                <link rel="apple-touch-icon" sizes="180x180" href="/icons/apple-touch-icon.png" />>
                <link rel="icon" type="image/png" sizes="32x32" href="/icons/favicon-32x32.png" />
                <link rel="icon" type="image/png" sizes="16x16" href="/icons/favicon-16x16.png" />
                <link rel="manifest" href="/icons/site.webmanifest" />
                <link rel="mask-icon" href="/icons/safari-pinned-tab.svg" color="#5bbad5" />
                <meta name="msapplication-TileColor" content="#2d89ef" />
                <meta name="theme-color" content="#ffffff" />
                <meta name="description" content="Sanofi Genzyme design system" />>
            </Helmet>

            <div className="lg:flex">
                <Header title="Flight" />

                <main className="p-4 pt-8 lg:p-16 lg:pt-20 lg:w-full">
                    <div className={`page lg:max-w-3xl mx-auto ${(!loaded) ? `opacity-0` : `opacity-100`}`}>
                        {children}
                    </div>
                </main>
            </div>
        </>
    )
};

export default Layout;
