import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import MenuLink from './link';

const MenuItem = ({ item }) => {
    const subPages = useStaticQuery(graphql`query {
        allMdx(sort: {
            order: ASC,
            fields: fileAbsolutePath
        }) {
            edges {
                node {
                    id
                    frontmatter {
                        path
                        title
                        parent
                    }
                }
            }
        }
    }`);

    const subItems = subPages.allMdx.edges.filter(({ node }) => node.frontmatter.parent === item.frontmatter.slug);

    return <li key={item.id}>
        {(item.frontmatter.title === 'Sanofi Design System') ? '' :
            <span className="block mt-8 px-4 py-1 text-xs text-white opacity-50 uppercase lg:text-secondary lg:opacity-100 tracking-wider font-bold lg:px-6 lg:mb-3">{item.frontmatter.title}</span>}

        <ul>
            {subItems.map(({ node }) => (
                <MenuLink key={node.id} id={node.id} item={node.frontmatter} />
            ))}
        </ul>
    </li>
};

export default MenuItem;
