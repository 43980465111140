import React from 'react';
import MenuItem from './item';
import { useStaticQuery, graphql } from 'gatsby';

const Menu = ({ isVisible }) => {  
    const parentPages = useStaticQuery(graphql`query {
        allMdx(
            sort: {
                fields: [fileAbsolutePath]
                order: ASC
            }
            filter: {
                frontmatter : { parent: { eq: null  }}
            }) {
            edges {
                node {
                    id
                    frontmatter {
                        path
                        slug
                        title
                    }
                }
            }
        }
    }`);

    const menuItems = Object.values(parentPages.allMdx.edges);

    return <aside className={`${isVisible ? `block` : `hidden`} pb-6 lg:block lg:pb-48`}>
        {(menuItems && menuItems.length > 0) ? <ul>
            {menuItems.map(({ node }) => {
                return <MenuItem key={node.id} item={node} />
            })}
        </ul> : <></>}
    </aside>
};

export default Menu;
